import React from "react";
import { Footer } from "../global/footer";

interface Props {}

export const LayoutServices: React.FC<Props> = props => {
  return (
    <div className="layout--services">
      {props.children}
      <Footer />
    </div>
  );
};
