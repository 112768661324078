import React, { useEffect } from "react";
import { LayoutServices } from "../components/layouts/layout-services";
import { Container, Row, Col } from "react-bootstrap";
import {
  IoIosCode,
  IoIosTrendingUp,
  IoMdLaptop,
  IoIosHome,
  IoIosCloud,
} from "react-icons/io";
import Scrollspy from "react-scrollspy";
import ScrollIndicator from "../components/shared/scroll-indicator";
import SEO from "../components/shared/seo";
import ContactUsBanner from "../components/shared/contact-us-banner";
import { LogoText } from "../components/shared/logo";
import instagramIcon from "../images/instagram-icon.svg";
import facebookIcon from "../images/facebook-icon.svg";
import linkedinIcon from "../images/linkedin-icon.svg";

interface Props {}

function Services() {
  return (
    <>
      <SEO
        title="Our Services"
        keywords="digital business strategy, website development maintenance, application development, business system integration"
      />
      <LayoutServices>
        <Scrollspy
          className="services__nav d-none d-sm-none d-md-none d-lg-flex"
          items={[
            "services-home",
            "application-development",
            "digital-business-strategy",
            "website-development-and-maintenance",
            "business-system-integration",
          ]}
          currentClassName="active"
          offset={-5}
        >
          <li>
            <a href="#services-home" className="services__nav__item">
              <div className="services__nav__item__icon">
                <IoIosHome size={20} />
              </div>
              <span>Home</span>
            </a>
          </li>
          <li>
            <a href="#application-development" className="services__nav__item">
              <div className="services__nav__item__icon">
                <IoIosCode size={20} />
              </div>
              <span>Application Development</span>
            </a>
          </li>
          <li>
            <a
              href="#digital-business-strategy"
              className="services__nav__item"
            >
              <div className="services__nav__item__icon">
                <IoIosTrendingUp size={20} />
              </div>
              <span>Digital Business Strategy</span>
            </a>
          </li>
          <li>
            <a
              href="#website-development-and-maintenance"
              className="services__nav__item"
            >
              <div className="services__nav__item__icon">
                <IoMdLaptop size={20} />
              </div>
              <span>Website Development & Maintenance</span>
            </a>
          </li>
          <li>
            <a
              href="#business-system-integration"
              className="services__nav__item"
            >
              <div className="services__nav__item__icon">
                <IoIosCloud size={20} />
              </div>
              <span>Business System Integration</span>
            </a>
          </li>
        </Scrollspy>

        <div
          id="services-content"
          className="page-layout"
          data-menu-class="inverted"
        >
          <section id="services-home" className="introduction">
            <Container className=" mb-5">
              <Row>
                <Col md={12} lg={8}>
                  <h6>Our service</h6>
                  <h2>GOOD SERVICE IS GOOD BUSINESS</h2>
                  <p>
                    Everything we do starts with a strategic analysis of your
                    business and marketing strategies. We know that in today’s
                    digital landscape, technology is an enabler and not a
                    starting point. Our proven inclusive approach takes a
                    holistic view of your business delivering outcomes-driven
                    digital solutions that align with your strategy.
                  </p>
                </Col>

                <Col md={12} lg={4} className="social-media">
                  <div className="social-media__links">
                    <div className="social-media__links__title">Follow us</div>

                    <div className="social-media__links__account">
                      <a
                        href="https://www.instagram.com/divivgroup/"
                        target="_blank"
                        rel="noreferrer"
                        className="social-icon__link"
                      >
                        <div className="icon">
                          {" "}
                          <img
                            src={instagramIcon}
                            style={{ width: "15px", height: "15px" }}
                            alt="Follow Diviv on Instagram"
                          />
                        </div>
                        Instagram
                      </a>
                    </div>

                    <div className="social-media__links__account">
                      <a
                        href="https://www.facebook.com/divivgroup/"
                        target="_blank"
                        rel="noreferrer"
                        className="social-icon__link"
                      >
                        <div className="icon">
                          {" "}
                          <img
                            src={facebookIcon}
                            className="facebook"
                            alt="Follow Diviv on Facebook"
                          />
                        </div>
                        Facebook{" "}
                      </a>
                    </div>

                    <div className="social-media__links__account">
                      <a
                        href="https://www.linkedin.com/company/17890171"
                        target="_blank"
                        rel="noreferrer"
                        className="social-icon__link"
                      >
                        <div className="icon">
                          {" "}
                          <img
                            src={linkedinIcon}
                            style={{ width: "15px" }}
                            alt="Follow Diviv on Linkedin"
                          />
                        </div>
                        Linkedin{" "}
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>

            <Container>
              <Row>
                <Col sm={12}>
                  <div className="diviv-banner">
                    <div className="diviv-banner__logo">
                      <LogoText />
                    </div>
                    <p className="diviv-banner__text">
                      We empower your business to do more at the intersection of
                      creativity & technology.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section id="application-development" className="services-section">
            <Container>
              <Row>
                <Col lg={3}>
                  <div className="services-section__icon">
                    <IoIosCode size={50} />
                  </div>
                </Col>
                <Col lg={7}>
                  <h4>Application Development</h4>
                  <p>
                    In a world that has gone digital, your software and
                    applications determine your competitive advantage.
                  </p>
                  <p>
                    Our custom developed applications allow you to differentiate
                    your business without compromising on design or
                    functionality. We know that your business is unique, so we
                    tailor our custom development services to your needs. Our
                    experience and expertise across multiple technologies give
                    us the agility we need to configure your solution to meet
                    your specific business goals.
                  </p>
                  <p>
                    Technology is the new competitive differentiator. In a
                    fast-changing global market, your technical solutions have
                    to be world-class, flexible and fast. Our skilled and
                    certified resources harness the latest ground-breaking
                    technologies and consistently deliver business outcomes
                    through innovative solutions.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>

          <section id="digital-business-strategy" className="services-section">
            <Container>
              <Row>
                <Col lg={3}>
                  <div className="services-section__icon">
                    <IoIosTrendingUp size={50} />
                  </div>
                </Col>
                <Col lg={7}>
                  <h4>Digital Business Strategy</h4>
                  <p>
                    In today’s online world your business needs to deliver a
                    lasting digital experience. Every digital encounter a user
                    has with your organisation will determine their perception
                    of your business. Leveraging the best UX experts and
                    designers, we benchmark your site against global best
                    practices ensuring your systems support your overall brand
                    experience.
                  </p>
                  <p>
                    Your digital business strategy must always consider the
                    customer experience. A great customer experience design is a
                    lot more than clever software engineering. At Diviv we build
                    solutions that ensure quality interactions between your
                    customers and your brand across all digital touch points.
                  </p>
                  <p>
                    Combining the expertise of leading developers, business
                    architects, and digital marketing specialists, we deliver a
                    digital business strategy that works.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>

          <section
            id="website-development-and-maintenance"
            className="services-section"
          >
            <Container>
              <Row>
                <Col lg={3}>
                  <div className="services-section__icon">
                    <IoMdLaptop size={50} />
                  </div>
                </Col>
                <Col lg={7}>
                  <h4>Website Development & Maintenance</h4>
                  <p>
                    Taking a brand-centric approach, we build websites around
                    what your customers need and what your brand wants to say.
                  </p>
                  <p>
                    In today’s digital-first world, your website is the first
                    interaction potential customers have with your business. Our
                    outcomes-driven approach means your audience and your
                    business goals are always at the forefront. Taking a
                    holistic view, we develop sites that tell a story
                    illustrating your unique differentiators and the impact you
                    can make.{" "}
                  </p>
                  <p>
                    We understand that websites are not static. They are a
                    window into your organisation and need to reflect your
                    vision and values. We take a long-term view and create sites
                    that can change as your business grows and transforms. Using
                    the latest digital and customer experience trends, we ensure
                    your website not only looks good but delivers your message
                    in a dynamic way.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>

          <section
            id="business-system-integration"
            className="services-section"
          >
            <Container>
              <Row>
                <Col lg={3}>
                  <div className="services-section__icon">
                    <IoIosCloud size={50} />
                  </div>
                </Col>
                <Col lg={7}>
                  <h4>Business System Integration</h4>
                  <p>
                    Our business system integration solutions help you transform
                    processes and technology investments into innovative
                    solutions.
                  </p>
                  <p>
                    With the right integration architecture, your business
                    stands to reduce total cost of ownership, increase customer
                    reach and drive operational efficiency. Our highly skilled
                    tech professionals can help you deliver an integrated
                    architecture that aligns with the strategies and goals of
                    your business. We take a long-term, holistic approach to all
                    our engagements and immerse ourselves in your business
                    partnering with you to ensure success.
                  </p>
                  <p>
                    Everything we do is as practical as possible. Our structured
                    approach takes a comprehensive view of your entire
                    enterprise architecture ensuring our integration solutions
                    maximise your technology investment. By analysing your
                    business processes and systems, we formulate and implement
                    an integration strategy that builds synergies and unlocks
                    business value.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LayoutServices>
    </>
  );
}

export default Services;
